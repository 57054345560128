// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enter-js": () => import("./../../../src/pages/enter.js" /* webpackChunkName: "component---src-pages-enter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-judges-js": () => import("./../../../src/pages/judges.js" /* webpackChunkName: "component---src-pages-judges-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-paperless-awards-js": () => import("./../../../src/pages/paperless-awards.js" /* webpackChunkName: "component---src-pages-paperless-awards-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-thankyou-js": () => import("./../../../src/pages/register-thankyou.js" /* webpackChunkName: "component---src-pages-register-thankyou-js" */),
  "component---src-pages-shortlist-js": () => import("./../../../src/pages/shortlist.js" /* webpackChunkName: "component---src-pages-shortlist-js" */),
  "component---src-pages-smarter-working-awards-js": () => import("./../../../src/pages/smarter-working-awards.js" /* webpackChunkName: "component---src-pages-smarter-working-awards-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-watch-js": () => import("./../../../src/pages/watch.js" /* webpackChunkName: "component---src-pages-watch-js" */),
  "component---src-pages-winners-js": () => import("./../../../src/pages/winners.js" /* webpackChunkName: "component---src-pages-winners-js" */)
}

